<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton 
        btnTitle="Add New" :showBtn="false" 
        :showAddNew="true" title="Requisition List"
        @onClickAddNewButton="onClickAddNewButton"
      />

      <DateQuerySetter @onClickGo="getRequisitions">
        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label for="colFormLabel" class="col-form-label">Project</label>
          <v-select
              placeholder="Select Project"
              v-model="projectId"
              :options="projects"
              label="name"
              :reduce="name => name.id"
          />
        </div>
        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label class="col-form-label" for="requisition">Requisition No</label>
          <input id="requisition" type="text" class="form-control" v-model="requisitionNumber">
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Status</label>
          <v-select
            placeholder="Select Status"
            v-model="status"
            :options="approvalStatusList"
            label="name"
            :reduce="name => name.id"
          />
        </div>
      </DateQuerySetter>
    </div>

    <ListTable :requisition-list="requisitionList" :approvalStatusList="approvalStatusList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, onMounted} from 'vue';
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/requisition/ListingTable'
import Pagination from '@/components/atom/Pagination'
import {useRoute, useRouter} from "vue-router";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleProjects from "@/services/modules/procurement/project";
import handleInventory from "@/services/modules/inventory";
import {useStore} from "vuex";

const {fetchRequisitionList} = handleRequisitions();
const {fetchProjects} = handleProjects()
const {fetchHome} = handleInventory();
const router = useRouter()
const route = useRoute()
const store = useStore()

const offset = ref(10);
let page = ref(1);
let loading = ref(false);
let requisitionList = ref({});
const projects = ref([]);
let projectId = ref(null);
let status = ref(null);
const requisitionNumber = ref('');
const homeContent = ref([]);
let companyId = computed(() => route.params.companyId);

function onClickAddNewButton() {
  router.push({name: 'requisition-add', params: route.params, query: route.query})
}

async function getRequisitions() {
  try {
    const res = await fetchRequisitionList(getQuery());
    if (res.status) {
      requisitionList.value = res.data;
      setPaginationData({
        records: res.data.total,
        perPage: res.data.per_page,
        currentPage: res.data.current_page,
      })
    }else{
      requisitionList.value = [];
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

const approvalStatusList = computed(() => {
    let approvalStatus = [];
    if(homeContent.value.hasOwnProperty('approval_status')) {
        Object.keys(homeContent.value.approval_status).forEach( id => {
            approvalStatus.push({
                id: id,
                name: homeContent.value.approval_status[id],
            })
        })
    }
    return approvalStatus;
})

function getQuery(skipPage = false) {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end

  if (!skipPage) {
    query += '&page=' + route.query.page ?? page.value
  }

  if (projectId.value) {
    query += '&project_id=' + projectId.value
  }

  if (requisitionNumber.value) {
    query += '&requisition_no=' + requisitionNumber.value
  }

  if (status.value) {
    query += '&status=' + status.value
  }
  return query
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getRequisitions();
  }, 100)
}

async function getProjects() {
  try {
    const res = await fetchProjects(getQuery(true));
    if (res.status) {
      projects.value = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

onMounted(async () => {
  await getRequisitions();
  await getProjects();
  fetchHome().then( res => {
      if(res.status) {
          homeContent.value = res.data;
      }
  })
})
</script>
